<template>
    <!-- 客户资料 -->
    <div class="customerData">
        <div class="search">
            <div class="search-l">
                <div class="l-item">
                    <div class="item-label">客户名称:</div>
                    <div class="item-input">
                        <el-input v-model="searchForm.name" placeholder="请输入客户名称" />
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">联系电话:</div>
                    <div class="item-input">
                        <el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
                    </div>
                </div>
                <div class="l-item">
                    <div class="item-label">路线:</div>
                    <div class="item-input">
                        <el-select v-model="searchForm.delivery_line_id" placeholder="请选择路线" style="width: 100%;"
                            @visible-change="visibleChange($event, 2)">
                            <el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="l-item">
                    <el-button type="warning" @click="loading = true, page = 1, getList()">搜索</el-button>
                    <el-button @click="loading = true, page = 1, pageNum = 10, searchForm = {}, getList()">重置</el-button>
                </div>
                <i></i>
                <i></i>
                <i></i>
                <i></i>
            </div>
        </div>
        <div class="table">
            <div class="table-operation">
                <el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
            </div>
            <Table :data="tableData" :total="total" v-loading="loading"
                @size-change="pageNum = $event, loading = true, getList()"
                @current-change="page = $event, loading = true, getList()">
                <el-table-column label="客户名称" prop="name" align="center"></el-table-column>
                <el-table-column label="联系电话" prop="phone" align="center"></el-table-column>
                <el-table-column label="地址" prop="address" align="center"></el-table-column>
                <el-table-column label="所在分组" prop="group_name" align="center"></el-table-column>
                <el-table-column label="上次下单" align="center">
                    <template slot-scope="scope">
                        <p style="color: red;"
                            v-if="scope.row.trade_diff_day > scope.row.warning_trade_day && scope.row.warning_trade_day !== 0">
                            {{ scope.row.trade_diff_day }}天前</p>
                        <p v-else> {{ scope.row.trade_diff_day }}天前</p>
                        <p style="color: red;"
                            v-if="scope.row.trade_diff_day > scope.row.warning_trade_day && scope.row.warning_trade_day !== 0">
                            {{ scope.row.last_trade_at }}</p>
                        <p v-else> {{ scope.row.last_trade_at }}</p>
                    </template>
                </el-table-column>
                <el-table-column label="最近30天" prop="last_thirty" align="center" sortable width="110"></el-table-column>
                <el-table-column label="信用额度" prop="credit_total" align="center" sortable></el-table-column>
                <el-table-column label="账期" align="center">
                    <template slot-scope="scope">
                        <span>{{ scope.row.credit_days }}天</span>
                    </template>
                </el-table-column>
                <el-table-column label="已用额度" prop="used_credit_total" align="center" sortable></el-table-column>
                <el-table-column label="所属路线" prop="delivery_line_name" align="center"></el-table-column>
                <el-table-column label="是否配送" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.is_delivery == 0">仅自提</span>
                        <span v-if="scope.row.is_delivery == 1">配送</span>
                    </template>
                </el-table-column>
                <el-table-column label="配送费规则" align="center">
                    <template slot-scope="scope">
                        <span>配送费{{ scope.row.delivery_fee }}元，单笔订单满{{ scope.row.free_delivery_total }}元免收配送费</span>
                    </template>
                </el-table-column>
                <el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
                <el-table-column label="操作" align="center" width="240">
                    <template slot-scope="scope">
                        <el-button type="warning" size="mini"
                            @click="dialogVisible = true, openDialog(scope.row)">编辑</el-button>
                        <el-button type="danger" size="mini" @click="removeFn(scope.row)">删除</el-button>
                        <el-button type="info" size="mini"
                            @click="tableDataItem = scope.row, blackDialog = true">拉黑</el-button>
                    </template>
                </el-table-column>
            </Table>
        </div>
        <!-- 编辑 -->
        <el-dialog title=" 编辑" :visible.sync="dialogVisible" :close-on-click-modal="false" v-el-drag-dialog width="30%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="客户名称">
                    <el-input v-model="form.name" placeholder="请输入客户名称"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item label="所在分组">
                    <el-select v-model="form.group_name" placeholder="请选择所在分组" style="width: 100%;"
                        @visible-change="visibleChange($event, 1)" @change="form.group_id = $event">
                        <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="信用额度">
                    <el-input v-model="form.credit_total" placeholder="请输入信用额度"></el-input>
                </el-form-item>
                <el-form-item label="账期">
                    <el-input v-model="form.credit_days" placeholder="请输入账期"></el-input>
                </el-form-item>
                <el-form-item label="所属路线">
                    <el-select v-model="form.delivery_line_name" placeholder="请选择所属路线" style="width: 100%;"
                        @visible-change="visibleChange($event, 2)" @change="form.delivery_line_id = $event">
                        <el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="是否配送">
                    <el-select v-model="form.is_delivery" placeholder="请选择是否配送" style="width: 100%;"
                        @change="form.is_delivery = $event">
                        <el-option v-for="item in deliveryList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="配送费">
                    <el-input v-model="form.delivery_fee" placeholder="请输入配送费"></el-input>
                </el-form-item>
                <el-form-item label="单笔订单免配送费的金额" label-width="180px">
                    <el-input v-model="form.free_delivery_total" placeholder="请输入单笔订单免配送费的金额"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="warning" @click="confirmFn">确 定</el-button>
            </span>
        </el-dialog>
        <!-- 拉黑 -->
        <el-dialog title="拉黑" :visible.sync="blackDialog" :close-on-click-modal="false" v-el-drag-dialog width="30%">
            <el-form ref="form" :model="blackForm" label-width="80px">
                <el-form-item label="拉黑原因">
                    <el-input v-model="blackForm.remark" placeholder="请输入加入黑名单原因"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="blackDialog = false">取 消</el-button>
                <el-button type="warning" @click="blackFn">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            searchForm: {
                name: '',
                phone: ''
            },
            tableData: [],
            tableDataItem: {},
            loading: true,
            page: 1,
            pageNum: 10,
            total: 0,
            // 分组
            groupList: [],
            // 所属路线
            routeList: [],
            // 编辑弹窗
            dialogVisible: false,
            form: {},
            // 是否配送
            deliveryList: [
                {
                    id: 0,
                    name: '不配送仅自提'
                },
                {
                    id: 1,
                    name: '可配送'
                }
            ],
            blackDialog: false,
            blackForm: {}
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            this.$http.post(`erp/v1/customer/lst`, {
                page: this.page,
                pageNum: this.pageNum,
                ...this.searchForm
            }).then((res) => {
                const { code, data, msg } = res.data
                this.loading = false
                if (code == 200) {
                    this.tableData = data.rows
                    this.total = data.total
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 编辑弹窗
        openDialog(item) {
            this.form = { ...item }
        },
        // 分组
        getGroupList() {
            this.$http.post(`erp/v1/customer_group/all`).then((res) => {
                const { code, data, msg } = res.data
                if (code == 200) {
                    this.groupList = data
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 所属路线
        getRouteList() {
            this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
                const { code, data, msg } = res.data
                if (code == 200) {
                    this.routeList = data
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 点击下拉框
        visibleChange(e, type) {
            if (e == true) {
                if (type == 1) {
                    this.getGroupList()
                } else {
                    this.getRouteList()
                }
            }
        },
        // 确定
        confirmFn() {
            this.$http.post(`erp/v1/customer/modify`, this.form).then((res) => {
                const { code, msg } = res.data
                if (code == 200) {
                    this.$message.success(msg);
                    this.dialogVisible = false
                    this.getList()
                } else {
                    this.$message.error(msg);
                }
            });
        },
        // 删除
        removeFn(item) {
            this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`erp/v1/customer/delete`, {
                    id: item.id,
                    is_black: 0
                }).then((res) => {
                    const { code, msg } = res.data
                    if (code == 200) {
                        this.$message.success(msg);
                        this.getList()
                    } else {
                        this.$message.error(msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        // 拉黑
        blackFn() {
            this.$confirm('此操作将拉黑该用户, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.$http.post(`erp/v1/customer/delete`, {
                    id: this.tableDataItem.id,
                    remark: this.blackForm.remark,
                    is_black: 1
                }).then((res) => {
                    const { code, msg } = res.data
                    if (code == 200) {
                        this.$message.success(msg);
                        this.blackDialog = false
                        this.getList()
                    } else {
                        this.$message.error(msg);
                    }
                });
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
    },
}
</script>
    
<style lang="scss" scoped></style>